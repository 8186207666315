<template>
  <div>
    <div class="flex items-center justify-center h-screen verify">
      <div class="hidden sm:block w-1/2 bg-cover h-screen" style='background-position: center;background-size: contain;background-repeat: no-repeat;background-image: url(https://img1.wsimg.com/isteam/ip/79554c9f-e439-4873-ab25-676d3604a380/Screen%20Shot%202021-07-25%20at%208.33.12%20PM.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:1390,h:1194,cg:true)'>
        <div class="bg-white-800 w-full h-screen bg-opacity-20">
        </div>
      </div>
      <div class="sm:w-1/2">
        <div class="p-5 w-4/5 mx-auto text-left font-raleway">
          <h1 class="font-bold text-left font-montserrat text-4xl sm:text-6xl mb-10">
            Your account is verified successfully. You can use your account now
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verify',

  data() {
    return {
      email: '',
      done: false,
      error: false,
    }
  }
}

</script>
<style scoped>
</style>

